@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css');

body,
button,
html {
  font-family: Inter;
}

body {
  background: rgb(17 24 39 / 1)
}

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;

}


.container{
  margin-inline: auto  !important;
}

.css-5g0doo {
  box-sizing: border-box;
  width: 100%;
  padding-inline: 12px;

  // color: var(--tux-colorTextPrimary); 
  // background-color: var(--tux-colorBGPrimary); 
  //border: 1px solid var(--tux-colorLineSecondary); 
  // border-radius: 2px; 
  outline: none;
  height: 36px;
  //border : 1px solid #e0e0e0;
}


*,
:before,
:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}


.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  scale: 0.5;
}

.lds-ripple div {
  position: absolute;
  border: 5px solid rgb(228, 72, 72);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.StepsNumber {
  background-color: rgb(254, 44, 85);
  color: #ffffff;
  padding: 3px;
  font-size: 20px;
  border-radius: 50%;
  margin-inline: 8px;

}

.StepsLabel {
  font-size: 16px;
  opacity: 0.8;
}


/* // Simple Scroll */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important
}

::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.1) !important
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.2) !important
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4) !important
}

::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .9) !important
}


.is-squircle {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.mask.is-squircle {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
}






:root {
  color-scheme: dark;
}


:backdrop {
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}



.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.odometer.odometer-auto-theme {
  display: inline-block;
  display: -moz-inline-box;
  vertical-align: middle;
  position: relative;
  line-height: 1.3em;
}

.odometer.odometer-auto-theme .odometer-digit {
  display: -moz-inline-box;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer {
  display: -moz-inline-box;
  display: inline-block;
  vertical-align: middle;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner {
  display: block;
}

.odometer.odometer-auto-theme .odometer-value {
  text-align: center;
}

.odometer .odometer-digit .odometer-value {
  width: 100%;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value {
  display: block;
}


//add animation to path element with classname CircularProgressbar-path so path gets filled up and down smoothly in random places
.CircularProgressbar-path {
  animation: fillUp 5s ease-in-out forwards;
  //infinite animation 
  animation-iteration-count: infinite;
}


@keyframes fillUp {
  0% {
    stroke-dashoffset: 240px;
  }

  20% {
    stroke-dashoffset: 180px;
  }

  30% {
    stroke-dashoffset: 180px;
  }

  40% {
    stroke-dashoffset: 120px;
  }

  50% {
    stroke-dashoffset: 120px;
  }

  60% {
    stroke-dashoffset: 60px;
  }

  70% {
    stroke-dashoffset: 60px;
  }

  80% {
    stroke-dashoffset: 0px;
  }

  90% {
    stroke-dashoffset: 0px;
  }

  100% {
    stroke-dashoffset: 240px;
  }

}




* {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

#exampleModal3, #exampleModal2, #errorModel, #exampleModal{
  background: rgba(0, 0, 0, .8);
  z-index: 999;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  .modal-dialog.modal-dialog-centered, .modal-dialog{
    margin: 1.75rem auto;
    max-width: 500px;
    align-items: center;
    display: flex;
    min-height: calc(100% - 3.5rem);
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    .modal-content{
      background-color: rgb(17 24 39 / 1);
      border-radius: 0.375rem;
      --tw-bg-opacity: 1;
      transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    }
  }
}
#exampleModal2, #errorModel, #exampleModal{
  .modal-dialog{
    display: block;
  }
}

.p-m-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  margin-left: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background: #25bc91;
}

.accordion-button::after {
  display: none;
}

.minus {
  position: absolute;
  width: 38%;
  height: 2px;
  border-radius: 20px;
  background-color: #fff;
}

.plus {
  position: absolute;
  width: 2px;
  height: 38%;
  border-radius: 20px;
  background-color: #fff;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-NyxcX.style-NyxcX {
  width: 2px;
  height: 40%;
}


.icon {
  transition: transform 0.5s ease-in-out;
}

.icon.rotate {
  transform: rotate(90deg);
}

.text-gradient__amber {
  padding-right: 0.05em;
  background-image: linear-gradient(191deg, #f1dc39 25%, #c96e05 87%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.arrows {
  width: 60px;
  height: 72px;
  //position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
  margin: auto;
}

.arrows path {
  stroke: #ccdae7;
  fill: transparent;
  stroke-width: 1.5px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}

@keyframes ldio-zxrz71mlja-r {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8)
  }

  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1)
  }

  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(180deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes ldio-zxrz71mlja-s {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.2 0 0.8 0.8)
  }

  50% {
    animation-timing-function: cubic-bezier(0.2 0.2 0.8 1)
  }

  0% {
    transform: translate(-30px, -30px) scale(0)
  }

  50% {
    transform: translate(-30px, -30px) scale(1)
  }

  100% {
    transform: translate(-30px, -30px) scale(0)
  }
}

.ldio-zxrz71mlja>div {
  transform: translate(0px, -15px)
}

.ldio-zxrz71mlja>div>div {
  animation: ldio-zxrz71mlja-r 1s linear infinite;
  transform-origin: 100px 100px;
}

.ldio-zxrz71mlja>div>div>div {
  position: absolute;
  transform: translate(100px, 82px);
}

.ldio-zxrz71mlja>div>div>div>div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #00f2ea;
  animation: ldio-zxrz71mlja-s 1s linear infinite;
}

.ldio-zxrz71mlja>div>div:last-child {
  animation-delay: -0.5s;
}

.ldio-zxrz71mlja>div>div:last-child>div>div {
  animation-delay: -0.5s;
  background: #ff0050;
}

.loadingio-spinner-interwind-flyom1cz6sv {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff00;
}

.ldio-zxrz71mlja {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-zxrz71mlja div {
  box-sizing: content-box;
}



* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


.footer-1-dark-with-cta {
  position: relative;
  z-index: 10;
  width: 100%;
  margin-top: 4em;
  padding-bottom: 2em;
  background-color: #0d1117;
  color: #0d1117;
}

.container-lg {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 1260px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1em;
  padding-left: 1em;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.footer-grid {
  position: relative;
  z-index: 5;
  display: -ms-grid;
  display: grid;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-1-main-grid {
  width: 100%;
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-1-meta {
  padding-top: 1em;
  border-top: 1px solid #21262d;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdfd-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

a {
  background-color: transparent;
  transition: opacity .3s, background-color .3s, color .3s;
  color: #ccdae7;
  text-decoration: none;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.footer-1-logo {
  width: 8em;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdbe-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

a:active,
a:hover {
  outline: 0;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc0-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

.row-base {
  display: -ms-grid;
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.image-contain {
  position: relative;
  z-index: 5;
  width: 100%;
  line-height: 1;
  -o-object-fit: contain;
  object-fit: contain;
}

.footer-1-sub-grid {
  margin-top: .5em;
  align-items: start;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-1-link-gray {
  opacity: 1;
  transition: opacity 250ms, color 250ms;
  color: #646f7c;
  font-size: .875em;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdff-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

.footer-1-link-gray:hover {
  color: #fff;
}

.column-sm {
  position: relative;
  display: -ms-grid;
  display: grid;
  justify-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 15px;
}

.margin-bottom {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc3-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc6-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc8-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdca-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdcd-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdd0-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdd2-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_73076f7e-6cc9-cc7a-d25c-ae6f209eb5cf-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

a:hover {
  outline: 0;
}

#w-node-b7133bbf-98fd-9d6a-32ef-e289edefea05-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbddd-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a7e12a0c-d703-16d6-f053-4efa14dbbde0-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_26f998d6-8cdc-e3e3-ebf9-2d5847978311-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f377beec-5f46-98d4-55de-6fae8da1f116-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5bc4843a-1d2d-e49c-05c8-2b00075cf7c6-2fe08bdf {
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1em;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0;
}

.footer-1-nav-heading {
  color: #ccdae7;
  font-size: .625em;
  font-weight: 700;
  letter-spacing: .01em;
  text-transform: uppercase;
}

strong {
  font-weight: 700;
}


.navbar-shadow2 {
  position: fixed;
  left: 0;
  top: 0px;
  right: 0;
  padding-block: 9px;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 85px;
  // background-color: rgba(13, 17, 23, 0.88);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(7px);
  z-index: 100;
  transition: all 0.3s ease-in-out;

}


/* These were inline style tags. Uses id+class to override almost everything */
#style-MAxil.style-MAxil {
  will-change: opacity;
  opacity: 1;
}



.w-nav {
  position: relative;
  background: #ddd;
  z-index: 1000;
}

.navbar {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 80px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}


.w-nav {
  position: relative;
  background: #ddd;
  z-index: 1000;
}

.navbar {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 80px;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}



.deatil-card {
  max-width: 1000px;

  .card-content {
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;

    .result-cards {
      width: 100%;
      display: flex;
    }
  }
}

.navbar-list {
  position: relative;
  z-index: 1001;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.25em;
  padding-left: 1.25em;
  justify-items: start;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

a {
  background-color: transparent;
  transition: opacity .3s, background-color .3s, color .3s;
  color: #ccdae7;
  text-decoration: none;
}

.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333;
}

.brand {
  display: flex;
  width: 100px;
  padding: 20px 0;
  flex-direction: column;
  justify-content: center;
}

a:active,
a:hover {
  outline: 0;
}

nav {
  display: block;
}

.w-nav-menu {
  position: relative;
  float: right;
}

.navbar-nav-menu {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.navbar-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#w-node-_81e876b6-5b64-8b8a-899e-2f9b8e08377a-fa0cbf10 {
  -ms-grid-column-align: end;
  justify-self: end;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.image-contain {
  position: relative;
  z-index: 5;
  width: 100%;
  line-height: 1;
  -o-object-fit: contain;
  object-fit: contain;
}

.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.nav-link {
  z-index: 1;
  padding-right: 20px;
  padding-left: 20px;
  perspective: 800px;
  perspective-origin: 50% 0%;
  transform-origin: 50% 0%;
  color: #ccdae7;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .03em;
}

.nav-link._1st {
  padding-left: 0;
}

.nav-link:hover {
  opacity: 1;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.button-animated {
  position: relative;
  z-index: 5;
  display: flex;
  overflow: hidden;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #0052cc;
  transition: color .2s cubic-bezier(.165, .84, .44, 1);
  color: #fff;
  font-size: 1.125em;
  line-height: 1;
  font-weight: 500;
  text-align: center;
}

.button-animated.small {
  font-size: .75em;
  font-weight: 600;
  letter-spacing: 0;
}

.w-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.image-12 {
  width: 16px;
  margin-right: 10px;
}

.button-label {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-hover-background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, .1);
}

.column-grid {
  margin: 5px;
  // width: "30%";
  border-radius: "8px";
  max-width: 260px;
  overflow: hidden;
}

/* These were inline style tags. Uses id+class to override almost everything */
#style-Hdwsj.style-Hdwsj {
  transform: translate3d(-101%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  display: none;
}



.DashboardCardNumberTitle {
  color: #feb019;
  margin-top: -5px;
  // font-size: 30px;
}

.TotalNumbersTitle {
  font-size: 13px;
  opacity: 0.8;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #47505f;
  text-align: left;
  margin: 0;
}

.DashboardCard {
  background-color: white;
  border-radius: 1rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  padding: 1.25rem;
  min-height: 165px;
  margin: 0;
  margin-block: 20px;
}

.navbar-list {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right-side {
    display: flex;
    align-items: center;
  }
}

.navbar-logo-navlinks {
  display: flex;
  align-items: center;
}



.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 16px;
  cursor: pointer;
}

.hamburger-menu span {
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.hamburger-menu.open span:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.hamburger-menu.open span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.mobile-signup {
  display: none;
  /* Add this line */

  position: relative;
  text-align: center;
  cursor: pointer;
  padding: 1rem;
  color: #fff;
  background-color: #00ffff;
}

.navbar-nav-menu {
  margin-left: 40px;
}


#view-active {
  background-color: #111827;
}

.caption-item {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  // margin: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  svg {
    height: 20px !important;

  }

  p {
    font-size: 12px;
  }
}


@media (min-width: 767px) {
  .hide_desktop {
    display: none;
  }

}

@media (max-width: 767px) {
  .button-animated.small.hide_mobile {
    display: none;
  }

  .navbar-nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(13, 17, 23, 0.88);
    width: 100%;
    box-sizing: border-box;
    padding-left: 1rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    margin-left: 0;
  }

  .navbar-nav-menu.menu-open {
    max-height: 400px;
    /* Adjust this value based on the height of your menu when fully opened */
  }


  .navbar-nav-menu a {
    margin-right: 0;
    padding: 1rem;
  }

  .hamburger-menu {
    display: flex;
  }

  .mobile-signup {
    display: block;
  }
}

.mobile-signup:hover {
  color: #4fd1c5;
}

.mobile-signup::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 17, 23, 0.88);
  z-index: -1;
  transition: all 0.3s ease;
}

.mobile-signup:hover::after {
  transform: scaleY(0);
}


.footer-container .column-sm {
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .filter-top-bar {
    flex-direction: column;

    .d-flex {
      margin-top: 10px;
      flex-wrap: wrap;
    }

    .ml-4 {
      margin-left: 0px !important;
      margin: 5px !important;
    }

  }

}

@media (max-width: 767px) {
  .footer-container .footer-grid {
    display: flex;
    flex-direction: column;
  }

  .footer-container .footer-1-main-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-container .footer-1-sub-grid {
    display: flex;
    flex-direction: column;
  }

  .footer-container .column-sm {
    margin-bottom: 1rem;
  }

  .footer-container .footer-1-meta {
    display: flex;
    justify-content: center;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.3s ease-in-out;
}

@keyframes skeleton {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.1;


  }

  100% {
    opacity: 0.3;
  }
}

.skeleton-effect {

  animation: skeleton 1.5s ease-in infinite;
}


.is-squircle {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

@font-face {
  font-display: block;
  font-family: Font Awesome\ 6 Free;
  font-style: normal;
  font-weight: 400;
  src: url(https://lineone.piniastudio.com/fonts/fontawesome/fa-regular-400.woff2) format("woff2"), url(https://lineone.piniastudio.com/fonts/fontawesome/fa-regular-400.ttf) format("truetype");
}

@font-face {
  font-display: block;
  font-family: Font Awesome\ 6 Free;
  font-style: normal;
  font-weight: 900;
  src: url(https://lineone.piniastudio.com/fonts/fontawesome/fa-solid-900.woff2) format("woff2"), url(https://lineone.piniastudio.com/fonts/fontawesome/fa-solid-900.ttf) format("truetype");
}



.px-\[var\(--margin-x\)\] {
  padding-left: var(--margin-x);
  padding-right: var(--margin-x);
}

.min-h-100vh {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh)*100);
}



.bg-slate-50 {
  background-color: #f8fafc;
  //color: #9947e6
}


.card {
  display: flex;
  --tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
  background-color: #e9eef573;
  border-radius: .5rem;
  color: #334155;
  flex-direction: column;
  min-width: 1px;
  overflow-wrap: break-word;
  position: relative;
  //box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}




.mask {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat-x: no-repeat;
  -webkit-mask-repeat-y: no-repeat;
}


.bg-warning\/10 {
  background-color: rgba(255, 152, 0, .1);
}

.mask.is-squircle {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
}


.rounded-lg {
  border-radius: .5rem;
}

p {
  margin: 0;
}

.text-xs\+ {
  font-size: .8125rem;
  line-height: 1.125rem;
}

.uppercase {
  text-transform: uppercase;
}


.items-baseline {
  align-items: baseline;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-warning {
  color: #ff9800;
}

.text-info {
  color: #0ea5e9;
}

.bg-info\/10 {
  background-color: rgba(14, 165, 233, .1);
}

.text-success {
  color: #10b981;
}

.bg-success\/10 {
  background-color: rgba(16, 185, 129, .1);
}

.fa-solid {
  display: var(--fa-display, inline-block);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-family: Font Awesome\ 6 Free;
  font-weight: 900;
}

.fa-user:before {
  content: "\f007";
}

.translate-x-1\/4 {
  --tw-translate-x: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/4 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 25%;
}



.opacity-15 {
  opacity: .15;
}


.text-slate-700 {
  color: #334155;
}



.text-success {
  color: #10b981;
}

.space-x-1> :not([hidden])~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(.25rem*(1 - var(--tw-space-x-reverse)));
  margin-right: calc(.25rem*var(--tw-space-x-reverse));
}


.graphic-container {
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

@keyframes dotPulse {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.dot {
  animation-name: dotPulse;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.tiktok-video {
  body {
    /* CSS Variables that may have been missed get put on body */
    --tw-text-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
  }

  .container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
    }
  }

  .container {
    margin: 0 auto;
    max-width: 1300px;
    padding: 0 30px;
  }

  .site-dashboard .container {
    max-width: none !important;
  }



  .site-dashboard {
    color: #343b47;
  }

  .site-dashboard.jsx-1970811892 {
    background: #fff;
    background: -webkit-linear-gradient(rgb(246, 249, 251)0%, rgba(248, 250, 253, 0)413px);
    background: -moz-linear-gradient(rgb(246, 249, 251)0%, rgba(248, 250, 253, 0)413px);
    background: -o-linear-gradient(rgb(246, 249, 251)0%, rgba(248, 250, 253, 0)413px);
    background: linear-gradient(rgb(246, 249, 251)0%, rgba(248, 250, 253, 0)413px);
    min-height: 100vh;
    overflow: hidden;
    padding-left: 210px;
  }

  #__next {
    min-height: 100vh;
  }

  body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;

    vertical-align: baseline;
    line-height: inherit;
    min-height: 100vh;
    background: #fff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-feature-settings: normal;
    min-height: 100vh;
  }

  :root {
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .w-1\/2 {
    width: 50%;
  }

  .pr-4 {
    padding-right: 1rem;
  }

  @media (min-width: 768px) {
    .md\:w-1\/4 {
      width: 25%;
    }
  }

  @media (min-width: 1024px) {
    .lg\:w-1\/5 {
      width: 20%;
    }
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
  }

  h3 {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: inherit;
    font: inherit;
    vertical-align: baseline;
    font-weight: inherit;

  }


  .font-bold {
    font-weight: 600;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .right-0 {
    right: 0;
  }

  .top-0 {
    top: 0;
  }

  .z-50 {
    z-index: 50;
  }

  .w-2\/3 {
    width: 66.666667%;
  }

  .flex-col {
    flex-direction: column;
  }

  .items-end {
    align-items: flex-end;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .pt-3 {
    padding-top: .75rem;
  }

  a {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    color: inherit;
    text-decoration: inherit;
  }

  .video.jsx-3131818978 {
    display: block;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #000;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 0;
    position: relative;
    min-width: 140px;
    width: 100%;
    height: 300px;
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
  }



  .video.jsx-3131818978:hover {
    -webkit-transform: scale3d(.98, .98, .98);
    -moz-transform: scale3d(.98, .98, .98);
    transform: scale3d(.98, .98, .98);
  }


  @media (min-width: 768px) {
    .md\:text-sm {
      font-size: .875rem;
    }
  }

  .hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(237 242 247/var(--tw-bg-opacity));
  }

  .hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
  }

  .video--img.jsx-3131818978 {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-transition: opacity .25 ease-in-out;
    -moz-transition: opacity .25 ease-in-out;
    -o-transition: opacity .25 ease-in-out;
    transition: opacity .25 ease-in-out;
    width: 100%;
    z-index: 2;
  }

  .video-overlay.jsx-3131818978 {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: rgb(240, 148, 51);
    background: -webkit-linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
    background: -moz-linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
    background: -o-linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
    background: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
    position: relative;
    z-index: 4;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  .video--shadow.jsx-3131818978 {
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 1), 0px 0px 12px rgba(0, 0, 0, 1);
  }

  .video--info.jsx-3131818978 {
    color: #fff;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    vertical-align: middle;
  }

  .video--title.jsx-3131818978 {
    display: -webkit-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: rgba(0, 0, 0, .4);
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .6)0%, rgba(0, 0, 0, .3)80%, rgba(0, 0, 0, 0)100%);
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, .6)0%, rgba(0, 0, 0, .3)80%, rgba(0, 0, 0, 0)100%);
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, .6)0%, rgba(0, 0, 0, .3)80%, rgba(0, 0, 0, 0)100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, .6)0%, rgba(0, 0, 0, .3)80%, rgba(0, 0, 0, 0)100%);
    color: #fff;
    bottom: 0;
    font-weight: 400;
    left: 0;
    line-height: 1.2;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    padding: 15px 10px 10px;
    position: absolute;
    word-break: break-word;
    width: 100%;
  }



  .video--icon.jsx-3131818978 {
    margin-right: 4px;
    opacity: .5;
    text-align: center;
    width: 26px;
  }

  span {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  svg {
    display: block;
    vertical-align: middle;
  }


}

.tox-statusbar__branding {
  display: none !important;
}

button[title="Save changes"] {
  background: #ffea84 !important;
  font-weight: 700 !important;
  // color: white!important;
}

button[title="Save changes"]:hover {
  background: #f8e065 !important;
  // color: white!important;
}

button[title="Cancel"] {
  background: #817979 !important;
  // color: white!important;
}

button[title="Cancel"]:hover {
  background: #3f3b3b !important;
  // color: white!important;
}

.tinymce-container p,
.tinymce-container h1 {
  font-family: 'Poppins', sans-serif;
}

iframe {
  margin: auto
}

.chart-container {
  width: 100%;
  overflow: hidden;
}

.outer-container {
  width: 90%;
  max-width: 1700px;
  margin: auto;
  margin-top: 12px;
}

.bio {
  max-width: 200px;
}

.growth-chart-pills {
  font-size: 0.80rem;
  /* Default font size */
  padding-block: 0.5rem;
}

.user-overview-main {
  display: flex;
  align-items: center;
}

@media (max-width: 1300px) {
  .outer-container {
    max-width: 715px;
  }

  .user-overview-cards {
    margin-top: 10px;
  }

  .growth-chart-pills {
    font-size: 0.75rem;
    /* Smaller font size */
  }

  .bio {
    max-width: 100%;
  }
}

@media (max-width: 840px) {
  .outer-container {
    max-width: 500px;
  }

  .user-overview-cards {
    justify-content: center;
  }

}

.user-overview-text {
  justify-content: space-around;
  text-align: left;
  ;
  flex-direction: column;

  display: flex;


  margin-left: 2.5rem;

}

.center-on-mobile {
  text-align: left;
}


@media (max-width: 540px) {
  .user-overview-cards {
    margin-top: 0px;
    justify-content: center;
  }

  .user-overview-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .user-overview-text {
    justify-content: space-around;

    flex-direction: column;

    display: flex;
    text-align: center;
    margin-left: 0;
    align-items: center
  }

  .center-on-mobile {
    text-align: center;
    margin-inline: auto;
  }


}

.bio-collapsed {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Show only 3 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tiktok-download-button {
  border-color: #ff0050 !important;
  color: #ff256a !important
}

.tiktok-download-button:hover {
  border-color: #ca0544 !important;
  color: #ffd2e1 !important;
  background-color: #ff0050 !important
}

.instagram-download-button {
  border-color: #FCAF45 !important;
  color: #FCAF45 !important
}

.instagram-download-button:hover {
  border-color: #ca8b32 !important;
  color: #ffe6c3 !important;
  background-color: #ce841d !important
}


@media (max-width: 1000px) {
  .date-min-width {
    min-width: 120px;
  }
}


// @media (max-width: 900px) {
//   .card {
//     width: 85% !important;
//   }
// }



.LoginForm {
  margin-top: 132PX;

  .block {
    // margin-top: 137px;
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
    background-color: #1c2333;
    border-radius: 20px !important;
  }
}

.filter-top-bar {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1340px;
  margin: auto;

  .right-side {
    display: flex;
    align-items: center;

    .dropdown-btn {
      border-radius: 3px;
      min-width: 160px;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;

    }

    .drop-box {
      padding: 5px 0px;
      min-width: 160px;
      background: #111827;
    }
    .dropdown-wrapper{
      position: absolute;
      top: 40px;
    } 
    .dropdown-item {
      display: flex;
      align-items: center;

      input {
        margin-right: 8px;
        height: 16px;
        width: 16px;
      }
    }
  }
}

.caption-container,
.caption-container a,
.caption-container img {
  height: 100% !important;
}

.durations {
  flex-direction: column;
  display: flex;
}

.chcekbox-box {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  width: 97%;

  input {
    height: 20px;
    width: 20px;
  }

  .model-btn {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    width: 35px;
    height: 35px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@media screen and (max-width: 768px) {
  .navbar-shadow2 {
    background: rgba(13, 17, 23, 0.88) !important;
  }

  .max-w-4xl {
    max-width: 100% !important;
    width: 100% !important;
  }

  .signup-btn-hide {
    display: none !important;
  }
}

.sub-card {
  position: relative;
  padding-top: 30px !important;
  padding-bottom: 60px !important;
  background: #1F2937 !important;

  .h5 {
    color: #25bc91 !important;
  }

  .card-body {
    padding: 20px;
    // margin-bottom: 40px;
  }

  p {
    font-size: 14px;
    line-height: 26px;

    color: rgb(204, 218, 231) !important;
  }

  .large-button {
    position: absolute !important;
    left: 20px;
    right: 20px;
    top: auto !important;
    bottom: 10px !important;
    width: calc(100% - 40px);
    height: 50px;
    background: #25bc91 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
}

.form-switch {
  display: flex;
  justify-content: center;
}

.date {
  font-size: 10px;
}

// .date-box {
//   background: rgba(0, 0, 0, 0.632);
//   position: absolute;
//   right: 5px;
//   top: 5px;
//   border-radius: 3px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 2px 5px;
//   font-size: 12px;
//   color: white;
// }
.btn-model:hover {
  background-color: #0000;
  color: #fff;
}

.youtube-video-card {
  width: calc(25% - 14px);
  margin: 7px;
  border-radius: 6px;
  overflow: hidden;

  .chcekbox-box input {
    height: 15px !important;
    width: 15px !important;
  }

  .caption {
    bottom: 0 !important;
    width: 100% !important;
    height: 50px;
    padding: 8px 0px 5px !important;
    display: flex !important;
    border-radius: 0px !important;

    p {
      font-size: 11px !important;
    }
  }


  .caption-item svg {
    height: 18px !important;
    width: 18px !important;
    margin-bottom: 5px !important;
  }

  .caption-item {
    margin-top: 0px !important;
    justify-content: space-between;
  }

  .chcekbox-box {
    left: auto !important;
    right: 10px !important;
  }

  .durations {
    padding: 5px 10px !important;
    left: 5px !important;
    top: 5px !important;
    border-radius: 4px !important;
    right: auto !important;
    bottom: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      margin-inline: 0px !important;
    }

    p {
      font-size: 10px !important;
    }
  }
}

.cards-row {
  display: flex;
  justify-content: space-between;
}

.pricing-card {
  width: 32%;
  color: white;
  padding: 40px 45px 110px;
  background: #171E25;
  transition: .3s ease-in-out all;
  &.active{
    transform: scale(1.05);
    background: #303e4d;
    transition: .3s ease-in-out all;
    .card-body{
      .btn{
        opacity: 1;
        background-color: #12A9A6 !important;
      }
      .icon i{
        color: #12A9A6;
      }
    }
  }
  .icon {
    height: 60px;
    width: 60px;
    margin: auto;
    margin-bottom: 30px;
    border: 2px solid #232A31;
    border-radius: 100px;
    background: #1E252C;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;



    i {
      color: #A7B5C3;
    }
  }

  .heading {
    text-align: center;
    font-size: 26px;
    width: 100%;
    margin-bottom: 0px;
  }

  .price {
    font-size: 36px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin: 20px 0px;
  }

  .card-text {
    text-align: left;

    p {
      line-height: 26px;
      font-size: 14px;
      font-weight: 300 !important;
    }

    strong {
      font-weight: 500;
    }
  }

  .btn {
    padding: 10px 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    text-transform: uppercase;
    width: 78%;
    display: block;
    background-color: #74787d !important;
    position: absolute !important;
    bottom: 40px;
    left: 50%;
    top: unset !important;
    transform: translateX(-50%);
    z-index: 9;
    &.subscribed{
      opacity: 1;
        background-color: #12A9A6 !important;
    }
  }
}

.bg-gray-700,
.create-new {
  background: #1F2937 !important;
}

.pricing-container {
  margin: 120px auto;
  max-width: 1200px;
}

.hero-section {
  max-width: 640px;
  margin: auto;
  margin-bottom: 60px;

  .top-icons {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 40px;

    .icons {
      margin: 0px 10px;
    }
  }

  .search-bar {

    .tag-icon {
      position: absolute;
      left: 20px;
      top: 10px;
    }

    .input-field {
      width: 100%;
      padding: 10px 15px 10px 40px;
      border-radius: 100px;
      margin-bottom: 40px;
    }
  }

  .search-btn {
    width: 100%;

    .s-btn {
      margin-bottom: 20px;
      border-radius: 3px;
      padding: 10px 15px;
      width: 100% !important;

      svg {
        margin-left: 10px;
      }
    }
  }

}

.add-folder-modal {
  .css-4iepb2 {
    background: #111827 !important;
    border: .5px solid #373737;
    border-radius: 5px;
  }

  #modal-modal-description {

    input {
      background: #1F2937 !important;
    }

    .m-cancel-btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        font-size: 14px;
        color: #dc3545;
      }
    }

    .text-base {
      .dropdown-item {
        padding: 10px 15px;

        input {
          height: 16px;
          width: 16px;
          margin-right: 10px;
        }

        i {
          margin-right: 10px;
          font-size: 16px;
        }
      }
    }

    .create-new {
      width: 100%;
      padding: 10px;
      text-align: center;
      font-size: 14px;

      i {
        margin-left: 7px;
      }
    }
  }
}
.nav-tab-button{
  max-width: 950px;
  margin: 0 auto 50px;
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    margin: 0 auto 20px;
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto 20px;
  }
  .nav-tabs{
    margin: 0 0 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    gap: 10px;
    padding: 20px 0;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    li{
      display: inline-block;
      &:not(&:last-of-type){
        margin: 0 10px 0 0;
        @media screen and (max-width: 767px) {
          margin: 0;
        }
      }
      a{
        font-size: 22px;
        font-weight: 600;
        background: rgb(28, 33, 56);
        padding: 20px 40px 20px 40px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // opacity: .7;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          font-size: 18px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          padding: 10px 20px 10px 20px;
          font-size: 16px;
        }
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
        &.active, &:hover{
          background: #303845;
          opacity: 1;
        }
        .icon{
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          margin: 0;
          svg{
            display: inline-block;
            font-size: 14px;
          }
        }
        .data{
          background: rgba(255, 165, 0, .2);
        }
        .video-icon{
          color:white;
        }
        .video-icon-wrapper{
          background-color: rgba(228, 6, 6, .3);
        }
        .analytics-icon{
          color: #10b981;
        }
        .analytics-icon-wrapper{
          background: rgba(16, 185, 129, 0.3);
        }
        .chart-icon{
          color: #ffffff;
        }
        .chart-icon-wrapper{
          background: rgba(235, 39, 182, .2);
        }
        span{
          display: inline-block;
          margin: 0 0 0 15px;
        }
      }
    }
  }
}

.description-model{
  display: -webkit-box;
  -webkit-line-clamp: 3;/* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-gradient__indigo-red {
  padding-right: 0.05em;
  background-image: linear-gradient(207deg, #e7004d 23%,#4400ff 95%);
 // background-image: linear-gradient(210deg, #001ce7 23%, #ff00bc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: saturate(1.5);
}